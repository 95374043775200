import './App.css';
import Pages from './pages';

import "./theme.scss";

function App() {
  return <Pages/>;
}

export default App;
