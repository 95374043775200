import { Avatar, Carousel, Col, Collapse, Divider, Form, Image, Input, Layout, Progress, QRCode, Row, Space, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { RightCircleFilled, PlusOutlined, MinusOutlined } from '@ant-design/icons';

import logo from "../../asset/logo.png"
import zigzag from "../../asset/zigzag.png"

import servicesImage1 from "../../asset/services/image-1.png"
import servicesImage2 from "../../asset/services/image-2.png"
import servicesImage3 from "../../asset/services/image-3.png"

import aboutCareerImage1 from "../../asset/about/career/image-1.png"
import aboutCareerImage2 from "../../asset/about/career/image-2.png"
import aboutCareerImage3 from "../../asset/about/career/image-3.png"

import facebook from "../../asset/icons/facebook.svg"
import locationBlack from "../../asset/icons/location-black.svg"
import mailBlack from "../../asset/icons/mail-black.svg"
import phoneBlack from "../../asset/icons/phone-black.svg"
import carretBlack from "../../asset/icons/caret-black.svg"

import "./style.scss"
import { Link, useParams } from "react-router-dom";

const Seemore = () => {
    const {type} = useParams()

    const [currentData, setcurrentData] = useState();

    useEffect(() => {
        if(type == "advice") {
            setcurrentData(dataServices[0])
        } else if(type == "support") {
            setcurrentData(dataServices[1])
        } else if(type == "help") {
            setcurrentData(dataServices[2])
        } else if(type == "requirements") {
            setcurrentData(dataCareer[0])
        } else if(type == "online") {
            setcurrentData(dataCareer[1])
        } else if(type == "signing") {
            setcurrentData(dataCareer[2])
        }
    },[type])

    const dataServices = [
        {
            image: servicesImage1,
            title: 'Advice',
            secondaryTitle: 'Comprehensive Analysis And Development',
            description: 'FAREAST provides full expertise during the whole preparation of the application process by assisting the Participant in all stages – from the definition of the key competencies for a specific position; we help in accordance with the needs, attitudes, and personal characteristics of the participant to identify meaningful and satisfying career. It is a process driven by their skills, knowledge, and capabilities. It allows us to work together, finding career paths and scope for professional growth. We at FAREAST support young graduates during their entry into the professional world.'
        },
        {
            image: servicesImage2,
            title: 'Support',
            secondaryTitle: 'Personal Support System',
            description: 'Interviews are the core and final stage of the application process. At FAREAST our goal is to help Candidates arrive at this stage while assisting them to be professionally prepared to complete the final interview round. Accordingly, we have created a personal training process, in which every Candidate is teamed with an experienced consultant who creates an elaborate business interview ‘training program’. Helping you to become clear and focused about your future training experience.'
        },
        {
            image: servicesImage3,
            title: 'Help',
            secondaryTitle: 'Improve and Optimize the Application',
            description: 'The selective process engages many talented candidates who compete for a very limited number of positions. For this reason, submitting an outstanding resume and other required documents is crucial for a Candidate prospect for progressing onto additional stages in the selection process. FAREAST helps you prepare the documents in your application to a standard that will give you the greatest opportunity for success but one that caters to the individual demands of each firm.'
        },
    ]

    const dataCareer = [
        {
            image: aboutCareerImage1,
            title: 'Requirements',
            secondaryTitle: 'Comprehensive Analysis And Development',
            description: 'Attend FAREAST Orientation to know and understand the details of Exchange Visitor Program and your training program. FAREAST will help you to complete all required documents. Email scanned copy of the following:'
        },
        {
            image: aboutCareerImage2,
            title: 'Online Application, Training, and Interview',
            secondaryTitle: 'Personal Support System',
            description: 'You will be invited to create your own account to complete your online application with the US Sponsor. FAREAST will assist you to complete your online application. A part of your application, you are required to attend a one-day English Training Refresher Course Training to enhance your communication and certify that you can speak in English. To explain more details about your training, you are required to attend a Skype Interview to meet representative of your US Sponsor.'
        },
        {
            image: aboutCareerImage3,
            title: 'Signing of Documents / J1 Visa Interview Schedule',
            secondaryTitle: 'Improve and Optimize the Application',
            description: 'FAREAST will help you to understand all the details of your program training in the United States. You will be assisted to accept, and sign required documents. We will assist you in your J1 visa interview schedule and attend series of embassy coaching. You will be assisted with all the required documents to attend the pre-departure orientation and secure CFO sticker.'
        },
    ]


    return (
        <div className="seemore-services">
            <nav>
                <img className="logo" src={logo}/>
                <div className="nav-links-container">
                    <ul>
                        <li>
                            <Link to={"/"}>Home</Link>
                        </li>
                    </ul>
                    <div className="divider"/>
                    <a className="facebook" target="_blank" href="https://www.facebook.com/fareastj1visa?mibextid=2JQ9oc"><img className="icon" src={facebook}/></a>
                </div>
            </nav>
            <div className="green-divider"/>
            <div id="about-container">
                <div className="program-container">
                    <div className="card-container">
                        <div className="training-phases">
                            <Row align="middle" className="title">
                                <div className="zigzag"/>
                                <h1></h1>
                                <img className="zigzag" src={zigzag}/>
                            </Row>
                            {currentData ? (
                                <div className="service-content">
                                    <img src={currentData.image}/>
                                    <h1>{currentData.title}</h1>
                                    <h2>{currentData.secondaryTitle}</h2>
                                    <p>{currentData.description}</p>
                                </div>
                            ):<></>}
                            {type && type == "requirements" ? (
                                <ul>
                                    <li>Resume</li>
                                    <li>Passport</li>
                                    <li>Diploma</li>
                                    <li>Driver’s License</li>
                                    <li>Transcript of Record</li>
                                    <li>Autobiography</li>
                                    <li>Passport Picture</li>
                                    <li>Certificate of Employment with Duties or Responsibilities for TRAINEE</li>
                                </ul>
                            ):<></>}
                        </div>
                    </div>
                </div>
            </div>
            <footer>
                <Row gutter={59} className="upper-container">
                    <Col span={6}>
                        <img className="logo" src={logo}/>
                    </Col>
                    <Col span={9}>
                        <p className="blue-header">Our Office</p>
                        <Row align={"middle"} className="details">
                            <img className="icon" src={locationBlack}/>
                            <p>912 Arnaiz Avenue, Makati City</p>
                        </Row>
                        <Row align={"middle"} className="details">
                            <img className="icon" src={phoneBlack}/>
                            <p>09668021992</p>
                        </Row>
                        <Row align={"middle"} className="details">
                            <img className="icon" src={mailBlack}/>
                            <p>usdocs@fareastj1.org</p>
                        </Row>
                    </Col>
                    <Col span={9}>
                        <p className="blue-header">Business Hours</p>
                        <Row align={"top"} className="details">
                            <img className="icon" src={carretBlack}/>
                            <Col>
                                <p>Monday - Friday</p>
                                <p className="time-text">09:00 am - 06:00 pm</p>
                            </Col>
                        </Row>
                        <Row align={"top"} className="details">
                            <img className="icon" src={carretBlack}/>
                            <Col>
                                <p>Saturday - Sunday</p>
                                <p className="time-text">Closed</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <div className="copy-right">
                    <p>© FAREAST, All Right Reserved.</p>
                </div>
            </footer>
        </div>
    )
}

export default Seemore