import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import Home from "./Home";
import Seemore from "./Seemore";
import SeemoreEVP from "./SeemoreEVP";

export default function Pages() {

  return (
    <Router>
      <Routes>
        <Route path={"/"} element={<Home/>} />
        <Route path={"/seemore/:type"} element={<Seemore/>} />
        <Route path={"/seemore_evp/:type"} element={<SeemoreEVP/>} />
      </Routes>
    </Router>
  );
}
