import { Avatar, Button, Carousel, Col, Collapse, Divider, Form, Image, Input, Layout, Progress, QRCode, Row, Space, message } from "antd";
import React, { useRef, useState } from "react";
import { RightCircleFilled, PlusOutlined, MinusOutlined, RightOutlined, LeftOutlined} from '@ant-design/icons';
import emailjs from '@emailjs/browser';

import logo from "../../asset/logo.png"
import zigzag from "../../asset/zigzag.png"

import carouselImage1 from "../../asset/carousel/image-1.png"
import carouselImage2 from "../../asset/carousel/image-2.jpeg"
import carouselImage3 from "../../asset/carousel/image-3.jpeg"
import carouselImage4 from "../../asset/carousel/image-4.jpeg"

import servicesImage1 from "../../asset/services/image-1.png"
import servicesImage2 from "../../asset/services/image-2.png"
import servicesImage3 from "../../asset/services/image-3.png"

import aboutImage1 from "../../asset/about/image-1.png"
import aboutImage2 from "../../asset/about/image-2.png"
import aboutImage3 from "../../asset/about/image-3.png"
import aboutImage4 from "../../asset/about/image-4.png"

import aboutProgramImage1 from "../../asset/about/program/image-1.png"
import aboutProgramImage2 from "../../asset/about/program/image-2.png"
import aboutProgramImage3 from "../../asset/about/program/image-3.png"
import aboutProgramImage4 from "../../asset/about/program/image-4.png"
import aboutProgramImage5 from "../../asset/about/program/image-5.png"
import aboutProgramImage6 from "../../asset/about/program/image-6.png"
import aboutProgramImage7 from "../../asset/about/program/image-7.png"
import aboutProgramImage8 from "../../asset/about/program/image-8.png"

import aboutAmbassadorImage1 from "../../asset/about/ambassador/millan.png"
import aboutAmbassadorImage2 from "../../asset/about/ambassador/cano.png"
import aboutAmbassadorImage3 from "../../asset/about/ambassador/publacion.png"
import aboutAmbassadorImage4 from "../../asset/about/ambassador/simbajon.png"
import aboutAmbassadorImage5 from "../../asset/about/ambassador/valdez.png"

import aboutCareerImage1 from "../../asset/about/career/image-1.png"
import aboutCareerImage2 from "../../asset/about/career/image-2.png"
import aboutCareerImage3 from "../../asset/about/career/image-3.png"

import millan from "../../asset/videos/millan.mp4"
import publacion from "../../asset/videos/publacion.mp4"
import simbajon from "../../asset/videos/simbajon.mp4"
import nodetails from "../../asset/videos/nodetails.mp4"

import map from "../../asset/contactus/map.png"
import facebook from "../../asset/icons/facebook.svg"
import location from "../../asset/icons/location.svg"
import mail from "../../asset/icons/mail.svg"
import phone from "../../asset/icons/phone.svg"
import locationBlack from "../../asset/icons/location-black.svg"
import mailBlack from "../../asset/icons/mail-black.svg"
import phoneBlack from "../../asset/icons/phone-black.svg"
import carretBlack from "../../asset/icons/caret-black.svg"

import "./style.scss"
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";

const Home = () => {

    const { TextArea } = Input;
    const [form] = Form.useForm()
    const ref = useRef()
    const carouselRef = React.useRef(null);
    const carouselRefTesti = React.useRef(null);
    const [isShowAll, setIsShowAll] = useState(false);

    const handleScrollTo = (elementID) => {
        const servicesContainer = document.getElementById(elementID);
        if (servicesContainer) {
            servicesContainer.scrollIntoView({ behavior: "smooth" });
        }
    };

    const handlePrevClick = () => {
        if (carouselRef.current) {
            carouselRef.current.prev();
        }
    };

    const handleNextClick = () => {
        if (carouselRef.current) {
            carouselRef.current.next();
        }
    };

    const handlePrevClickTesti = () => {
        if (carouselRefTesti.current) {
            carouselRefTesti.current.prev();
        }
    };

    const handleNextClickTesti = () => {
        if (carouselRefTesti.current) {
            carouselRefTesti.current.next();
        }
    };

    const handleShowAll = (show) => {
        setIsShowAll(show)
    }

    const panelStyle = {
        marginBottom: 20,
        border: 'none',
        borderRadius: "8px",
        boxShadow: "0px 3px 6px #00000029",
        padding: "20px 40px"
    };

    const getItems = () => [
        {
            key: '0',
            label: 'Who is Fareast?',
            children: <p>FAREAST is an independent, non-political, not-for-profit organization engaged in providing opportunities to experience world cultures through the Exchange Visitor Program. We connect young Filipino who want to enhance their skills and expertise in their academic or occupational fields through participation in a structured and international training-based program in the United States.</p>,
            style: panelStyle
        },
        {
            key: '1',
            label: 'Our Mission and Vision?',
            children: <div>
                <h4>MISSION</h4>
                <p>FAREAST will help and educate young Filipino professionals to pursue their dreams in enhancing their knowledge and developing skills. FAREAST believes that young Filipinos should aspire to experience the benefits of cultural exchange to enable them to be more competitive 	internationally.</p>
                <h4>VISION</h4>
                <p>FAREAST inspires young Filipino professionals to move towards their goal of being world-class renowned in their field of education by having International Training.</p>
            </div>,
            style: panelStyle
        },
        {
            key: '2',
            label: 'What documents do I need to submit to join EVP?',
            children: <div>
                <p>To join this exchange visitor program, initially, you have to submit the following documents:</p>
                <ul>
                    <li>Resume</li>
                    <li>Passport</li>
                    <li>Diploma</li>
                    <li>Driver’s License</li>
                </ul>
            </div>,
            style: panelStyle
        },
        {
            key: '3',
            label: 'Is there an age limit to join the EVP?',
            children: <p>Technically, under the rule, there is no age limit to participate in the Exchange Visitor Program but in reality, with the years we have been facilitating J1 visas, we noticed that those who are 30 years old or older had a low percentage to pass the J1 Visa interview. US Sponsors also prefer those who were in the younger years of their age to experience training in the United States except those who are fresh graduates.</p>,
            style: panelStyle
        },
        {
            key: '4',
            label: 'What is the purpose of the exchange visitor program?',
            children: <p>The Exchange Visitor Program fosters global understanding through educational and cultural exchanges.  All exchange visitors are expected to return to their home country upon completion of their program in order to share their exchange experiences.</p>,
            style: panelStyle
        },
        {
            key: '5',
            label: 'As an Exchange Visitor Visa holder, am I allowed to work?',
            children: <p>An Exchange Visitor Visa holder is only allowed to perform the activity listed on his/her Form DS-2019 and as stated in the regulations for that category of exchange.</p>,
            style: panelStyle
        },
        {
            key: '6',
            label: 'What is Form DS-2019 (Certificate of Eligibility)?',
            children: <p>A SEVIS-generated Form DS-2019 is required when applying for the J-1 Visa.  The DS-2019 is a controlled form provided to you by your program sponsor.   It documents your acceptance to an exchange program sponsored by a State Department designated Exchange Visitor Program sponsor.</p>,
            style: panelStyle
        },
        {
            key: '7',
            label: 'What will happen to my Exchange Visitor Visa status if my exchange visitor program sponsor terminates my exchange visitor program?',
            children: <p>If your sponsor terminates your participation in their exchange program for just cause, the sponsor will enter this information into SEVIS and you will be expected to depart the United States immediately. You will not be entitled to the post-completion 30-day period because you did not successfully complete your program.</p>,
            style: panelStyle
        },
    ];

    const onFinish = (e) => {

        e.preventDefault();

        emailjs.sendForm('service_bsfdrpo', 'template_tss6a3f', ref.current, '8GjTqKZyCtAk4p4kO')
        .then((result) => {
            console.log(result.text);
            message.success("Your message has been sent.")
            if (ref.current) {
                ref.current.elements.fullName.value = '';
                ref.current.elements.email.value = '';
                ref.current.elements.subject.value = '';
                ref.current.elements.message.value = '';
            }
        }, (error) => {
            console.log(error.text);
            message.error(error.text)
        });
    }
    

    return (
        <div className="home">
            <nav>
                <img className="logo" src={logo}/>
                <div className="nav-links-container">
                    <ul>
                        <li>
                            <a onClick={() => handleScrollTo("home-container")} >Home</a>
                        </li>
                        <li>
                            <a onClick={() => handleScrollTo("services-container")}>Services</a>
                        </li>
                        <li>
                            <a onClick={() => handleScrollTo("about-container")}>About</a>
                        </li>
                        <li>
                            <a onClick={() => handleScrollTo("contact-container")}>Contact</a>
                        </li>
                    </ul>
                    <div className="divider"/>
                    <a className="facebook" target="_blank" href="https://www.facebook.com/fareastj1visa?mibextid=2JQ9oc"><img className="icon" src={facebook}/></a>
                </div>
            </nav>
            <div className="green-divider"/>
            <div id="home-container">
                <div className="gradient-container">
                    <div className="headline-container">
                        <div className="welcome-green-container">
                            <div className="welcome-white-container">
                                <span>Welcome to</span>
                                <img src={logo}/>
                            </div>
                        </div>
                        <h1>USA TRAINING PROGRAMS</h1>
                        <h2>BRIDGES OPPORTUNITIES, CREATES FUTURE.</h2>
                        <p className="headline-caption">
                            Choosing the Best! Travel, Learn, Experience and Earn.<br/> Inviting graduates to stand out and acquire an exceptional training opportunity to participate in the Cultural Exchange Program.
                        </p>
                        <button className="join-btn" onClick={() => handleScrollTo("contact-container")}>Join Now <RightCircleFilled /></button>
                    </div>
                </div>
                <Carousel autoplay>
                    <div className="carousel-content">
                        <img src={carouselImage1}/>
                    </div>
                    <div className="carousel-content">
                        <img src={carouselImage2}/>
                    </div>
                    <div className="carousel-content">
                        <img src={carouselImage3}/>
                    </div>
                    <div className="carousel-content">
                        <img src={carouselImage4}/>
                    </div>
                </Carousel>
            </div>
            <div id="services-container">
                <div className="card-container">
                    <Row>
                        <Col span={8}>
                            <div className="service-content">
                                <img src={servicesImage1}/>
                                <h1>Advice</h1>
                                <h2>Comprehensive Analysis And Development</h2>
                                <p>
                                    FAREAST provides full expertise during the whole preparation of the application process by assisting the Participant in all stages – from the definition of the key competencies for a specific position; we help in accordance with the needs, attitudes, and personal characteristics of the participant to identify meaningful and satisfying career. It is a process driven by their skills, knowledge, and capabilities. It allows us to work together, finding career paths and scope for professional growth. We at FAREAST support young graduates during their entry into the professional world.
                                </p>
                                <Link to={"/seemore/advice"}>See more <RightCircleFilled /></Link>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div className="service-content divider">
                                <img src={servicesImage2}/>
                                <h1>Support</h1>
                                <h2>Personal Support System</h2>
                                <p>
                                    Interviews are the core and final stage of the application process. At FAREAST our goal is to help Candidates arrive at this stage while assisting them to be professionally prepared to complete the final interview round. Accordingly, we have created a personal training process, in which every Candidate is teamed with an experienced consultant who creates an elaborate business interview ‘training program’. Helping you to become clear and focused about your future training experience.
                                </p>
                                <Link to={"/seemore/support"}>See more <RightCircleFilled /></Link>
                            </div>
                        </Col>

                        <Col span={8}>
                            <div className="service-content divider">
                                <img src={servicesImage3}/>
                                <h1>Help</h1>
                                <h2>Improve and Optimize the Application</h2>
                                <p>
                                    The selective process engages many talented candidates who compete for a very limited number of positions. For this reason, submitting an outstanding resume and other required documents is crucial for a Candidate prospect for progressing onto additional stages in the selection process. FAREAST helps you prepare the documents in your application to a standard that will give you the greatest opportunity for success but one that caters to the individual demands of each firm.
                                </p>
                                <Link to={"/seemore/help"}>See more <RightCircleFilled /></Link>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <div id="about-container">
                <div className="success-and-values-container">
                    <p className="blue-header text-align-center">Our Success and Values</p>
                    <Row align="middle">
                        <div className="zigzag"/>
                        <div className="logo-container">
                            <img className="logo" src={logo}/>
                        </div>
                        <img className="zigzag" src={zigzag}/>
                    </Row>
                    <Row>
                        <Col span={12}>
                            {/* <img src={aboutImage1} /> */}
                            <Image src={aboutImage1}/>
                        </Col>
                        <Col span={12}>
                            <div className="description-container">
                                <p className="blue-header">Our Success and Values</p>
                                <p className="description text-align-left">
                                    To bring together young Filipino Graduates of Agriculture, College/University US Sponsoring Organizations, and Sponsoring hosts in exchange experiences that offer unique international perspectives and encourage the confidence to make a difference in our interconnected world.
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <div className="description-container align-content-right">
                                <p className="blue-header">Our Values</p>
                                <p className="description text-align-right">
                                    We Focus on the Key Process as we recognize<br/> the importance of excellence in ourselves and<br/> in the association. We develop to have the ability to <br/>encourage a cooperative spirit in those around us <br/>to give value for the time, effort, and money of our clients.
                                </p>
                                {/* <Link>See more <RightCircleFilled /></Link> */}
                            </div>
                        </Col>
                        <Col span={12}>
                            {/* <img src={aboutImage2} /> */}
                            <Image src={aboutImage2}/>
                        </Col>
                    </Row>
                </div>
                <div className="program-container">
                    <div className="card-container">
                        <p className="blue-header text-align-center">Exchange Visitor Program</p>
                        <Row align="middle">
                            <img className="zigzag" src={zigzag}/>
                            <h1>USA AGRICULTURAL TRAINING PROGRAMS</h1>
                            <div className="zigzag"/>
                        </Row>
                        <Row gutter={18} className="programs">
                            <Col span={6}>
                                {/* <img src={aboutProgramImage1}/> */}
                                <Image src={aboutProgramImage1}/>

                                <p className="blue-header">Poultry Production</p>
                                <p className="description elipsis">
                                    ● Safety and Operations Training.<br/>
                                    ● Flock Management-Avian Husbandry. <br/>
                                    ● Poultry Feed and Nutrition.<br/>
                                    ● Poultry Fock Management-Data and Documentation. <br/>
                                    ● Flock Management-Technology and Automation. <br/>
                                    ● Supervision and Poultry Operations Management.<br/>
                                </p>
                                <Link to={"/seemore_evp/poultry"}>See more <RightCircleFilled /></Link>
                            </Col>
                            <Col span={6}>
                                {/* <img src={aboutProgramImage2}/> */}
                                <Image src={aboutProgramImage2}/>

                                <p className="blue-header">Dairy Production</p>
                                <p className="description elipsis">
                                    ● Introduction and Overview of farm operations. <br/>
                                    ● Basic operations of the machinery and maintenance. <br/>
                                    ● Livestock husbandry and facility maintenance.<br/>
                                    ● Pasture Care, Welding, and Fabrication. <br/>
                                    ● Management and oversight.<br/>
                                </p>
                                <Link to={"/seemore_evp/dairy"}>See more <RightCircleFilled /></Link>
                            </Col>
                            <Col span={6}>
                                {/* <img src={aboutProgramImage3}/> */}
                                <Image src={aboutProgramImage3}/>

                                <p className="blue-header">Swine Production</p>
                                <p className="description elipsis">
                                    ● Introduction to Farrowing. <br/>
                                    ● Swine Lactation and Recovery. <br/>
                                    ● Intermediate Farrowing/Barn Management. <br/>
                                    ● Advanced Swine Production - Breeding. <br/>
                                    ● Management and Oversight.<br/>
                                </p>
                                <Link to={"/seemore_evp/swine"}>See more <RightCircleFilled /></Link>
                            </Col>
                            <Col span={6}>
                                {/* <img src={aboutProgramImage4}/> */}
                                <Image src={aboutProgramImage4}/>

                                <p className="blue-header">Crops Production</p>
                                <p className="description elipsis">
                                    ● Safety and Operations Training. <br/>
                                    ● Soil and Grid Sampling 1. <br/>
                                    ● Agricultural Laboratory Services. <br/>
                                    ● Soil and Grid Sampling 11.<br/>
                                </p>
                                <Link to={"/seemore_evp/crops"}>See more <RightCircleFilled /></Link>
                            </Col>
                        </Row>
                        <div className="divider"/>
                        <div className="training-phases">
                            <Row align="middle" className="title">
                                <div className="zigzag"/>
                                <h1>AGRONOMY TRAINING PLAN PHASES</h1>
                                <img className="zigzag" src={zigzag}/>
                            </Row>
                            <Row>
                                <Col span={12}>
                                    {/* <img src={aboutProgramImage5} /> */}
                                    <Image src={aboutProgramImage5}/>
                                </Col>
                                <Col span={12}>
                                    <div className="description-container">
                                        <p className="blue-header">Safety and Operations Training</p>
                                        <p className="description text-align-left">
                                            The participant will be given a general overview of facility operations and staff responsibilities to better understand the machinery, tools, and personnel needs of a successful operation. Equipment and safety procedures may differ considerably from previous experiences, therefore orientation and hands-on safety training must be completed to ensure personal safety during tasks/assignments during this and subsequent phases of the program. 
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12}>
                                    <div className="description-container align-content-right">
                                        <p className="blue-header">Soil and Grid Sampling I</p>
                                        <p className="description text-align-right">
                                            The participant will assist company agronomists in obtaining soil samples in fields. The participants will obtain samples, document and label exact GPS coordinates and will store and transport the samples to the laboratory for testing. The participant will also assist agronomists with the installation and extraction of soil moisture measurement probes.
                                        </p>
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <Image src={aboutProgramImage6}/>
                                </Col>
                            </Row>
                            
                            {isShowAll ? (
                                <>
                                    <Row>
                                        <Col span={12}>
                                            <Image src={aboutProgramImage5}/>
                                        </Col>
                                        <Col span={12}>
                                            <div className="description-container">
                                                <p className="blue-header">Agricultural Laboratory Services</p>
                                                <p className="description text-align-left">
                                                    The participant will undergo in-depth instruction regarding laboratory testing of Soil Testing, Feed and Forage Testing, Water Testing, Plant Tissue Testing, Fertilizer, Lime and Gypsum Testing, Manure and Compost Testing, Wastewater Testing and Environmental Solids Testing.
                                                </p>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}>
                                            <div className="description-container align-content-right">
                                                <p className="blue-header">Soil and Grid Sampling II</p>
                                                <p className="description text-align-right">
                                                    The participant will continue his training in obtaining soil samples in fields. The participant will obtain samples, document and label exact GPS coordinates and will store and transport the samples to the laboratory for testing. The EV will also assist agronomists with the installation and extraction of soil moisture measurement probes.
                                                </p>
                                            </div>
                                        </Col>
                                        <Col span={12}>
                                            {/* <img src={aboutProgramImage6} /> */}
                                            <Image src={aboutProgramImage6}/>
                                        </Col>
                                    </Row>
                                    <button className="show-less-btn " onClick={() => handleShowAll(false)}>Show Less</button>
                                </>
                            ):(
                                <button className="show-all-btn" onClick={() => handleShowAll(true)}>Show All</button>
                            )}
                        </div>
                        <div className="divider"/>
                        <div className="training-program">
                            <p className="blue-header text-align-center">Exchange Visitor Program - J1 Visa</p>
                            <Row align="middle">
                                <img className="zigzag" src={zigzag}/>
                                <h1>USA AGRICULTURAL TRAINING PROGRAMS</h1>
                                <div className="zigzag"/>
                            </Row>
                            <p className="description text-align-center title-description">
                                FAREAST will help and educate young Filipino professionals to pursue their dreams in enhancing their knowledge and developing skills. FAREAST believes that young Filipinos should aspire to experience the benefits of cultural exchange to enable them to be more competitive internationally. FAREAST inspires young Filipino professionals to move towards their goal of being world-class and renowned in their field of education by having International Training.
                            </p>
                            <Row gutter={18} className="programs">
                                <Col span={12}>
                                    {/* <img src={aboutProgramImage7}/> */}
                                    <Image src={aboutProgramImage7}/>
                                    <p className="blue-header">What is an exchange visitor program?</p>
                                    <p className="description elipsis">
                                        Exchange Visitor Program refers to the international exchange program administered by the United States to implement the Mutual Education and Cultural Exchange Act of 1961, as amended, Public Law 87-256, 22 USC 2451, et seq. (1988). The purpose of the Act is to increase mutual understanding between the people of the United States and the people of other countries by means of educational and cultural exchanges. Educational and cultural exchanges assist in furthering the United States' foreign policy objectives.
                                    </p>
                                    <Link to={"/seemore_evp/evp"}>See more <RightCircleFilled /></Link>
                                </Col>
                                <Col span={12}>
                                    {/* <img src={aboutProgramImage8}/> */}
                                    <Image src={aboutProgramImage8}/>
                                    <p className="blue-header">What is J1 Visa?</p>
                                    <p className="description elipsis">
                                        A J-1 visa is a non-immigrant visa issued by the United States to research scholars, professors and exchange visitors participating in programs that promote cultural exchange, especially to obtain medical or business training within the U.S. All applicants must meet eligibility criteria, English language requirements, and be sponsored either by a university, private sector or government program. J-1 visa holders must return home for two years following visa expiration so they impart cultural knowledge learned in the United States.
                                    </p>
                                    <Link to={"/seemore_evp/j1-visa"}>See more <RightCircleFilled /></Link>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
                <div className="graduates">
                    <p className="blue-header text-align-center">Graduates</p>
                    <Row align="middle">
                        <div className="zigzag"/>
                        <h1>USA AGRICULTURAL TRAINING PROGRAMS</h1>
                        <img className="zigzag" src={zigzag}/>

                    </Row>
                    <p className="description text-align-center title-description">
                        The general purpose of the USA Exchange Visitor Program is to promote international educational and cultural exchange to develop mutual understanding between the people of the United States and other countries. An opportunity to increase and understand the American culture and society while enhancing their skills in their field of study or profession and expertise through structured and guided training.
                    </p>
                    <Row>
                        <Col span={12}>
                            {/* <img src={aboutImage3} /> */}
                            <Image src={aboutImage3}/>

                        </Col>
                        <Col span={12}>
                            <div className="description-container">
                                <p className="blue-header">Internship Program</p>
                                <p className="description text-align-left">
                                    Internships Program is designed for graduating students and recent graduates to acquire professional work experience and enhance their technical skills associated with their major field of study while being immersed in American lifestyle and culture. Participants can expect to be trained in various locations in the USA for qualified host companies dedicated to delivering structured and practical training programs that abide by the US Department of State Exchange Visitors Program criteria.
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <div className="description-container align-content-right">
                                <p className="blue-header">Trainee Program</p>
                                <p className="description text-align-right">
                                    A Trainee has graduated from a certificate granting educational institution for more than 1 year from the date of graduation PLUS has one year of experience outside the US. This is high-level training that is more advanced than entry-level.
                                </p>
                            </div>
                        </Col>
                        <Col span={12}>
                            {/* <img src={aboutImage4} /> */}
                            <Image src={aboutImage4}/>

                        </Col>
                    </Row>
                </div>
                <div className="graduate-stats">
                    <div className="gradient-container">
                        <div className="content">
                            <p className="white-header text-align-center">Graduates</p>
                            <Row align="middle">
                                <img className="zigzag" src={zigzag}/>
                                <h1>VISITS UNIVERSITIES</h1>
                                <div className="zigzag"/>
                            </Row>
                            <Row justify={"center"}>
                                <Progress className="progress-bar" type="circle" percent={31} size={248} trailColor="#1164A8" strokeColor="#0483eb" strokeLinecap="butt" strokeWidth={15} format={(percent) => (
                                    <div className="progress-text">
                                        <p className="percentage">{percent}</p>
                                        <p>Luzon</p>
                                    </div>
                                )} />
                                <Progress className="progress-bar" type="circle" percent={12} size={248} trailColor="#1164A8" strokeColor="#0483eb" strokeLinecap="butt" strokeWidth={15} format={(percent) => (
                                    <div className="progress-text">
                                        <p className="percentage">{percent}</p>
                                        <p>Visayas</p>
                                    </div>
                                )} />
                                 <Progress className="progress-bar" type="circle" percent={11} size={248} trailColor="#1164A8" strokeColor="#0483eb" strokeLinecap="butt" strokeWidth={15} format={(percent) => (
                                    <div className="progress-text">
                                        <p className="percentage">{percent}</p>
                                        <p>Mindanao</p>
                                    </div>
                                )} />
                            </Row>
                        </div>
                    </div>
                </div>
                <div className="ambasador-container">
                    <div className="upper-container">
                        <img className="logo" src={logo}/>
                        <h1>AMBASSADOR</h1>
                    </div>
                    <Row align={"middle"} justify={"center"} gutter={100}>
                        <Button onClick={handlePrevClick} icon={<LeftOutlined />} shape="circle" tpype="primary"></Button>
                        <Col span={20}>
                            <Carousel ref={carouselRef} dots={false}>
                                <div>
                                    <div className="carousel-ambassador">
                                        <Row gutter={12}>
                                            <Col span={8}>
                                                <div className="card-container">
                                                    <Avatar size={110} src={aboutAmbassadorImage1} />
                                                    <p className="name">Christian E. Millan</p>
                                                    <p className="position">Tarlac Agricultural University</p>
                                                    <p className="description">EVP Participant from</p>
                                                    <p className="description-blue">Rose Acre Farms.</p>
                                                    <p className="description">at Francesville, IN</p>
                                                    {/* <p className="caption">Congratulations! We are proud of you. Inviting all Graduates of Agriculture Courses who want to experience Agricultural Training in the United States.</p> */}
                                                </div>
                                            </Col>
                                            <Col span={8}>
                                                <div className="card-container blue-card">
                                                    <Avatar size={110} src={aboutAmbassadorImage2} />
                                                    <p className="name">Ethelbert V. Cano</p>
                                                    <p className="position">Batanes State College</p>
                                                    <p className="description">EVP Participant from</p>
                                                    <p className="description-white">Rose Acre Farms.</p>
                                                    <p className="description">at North Vernon, IN</p>
                                                    {/* <p className="caption">Congratulations! We are proud of you. Inviting all Graduates of Agriculture Courses who want to experience Agricultural Training in the United States.</p> */}
                                                </div>
                                            </Col>
                                            <Col span={8}>
                                                <div className="card-container">
                                                    <Avatar size={110} src={aboutAmbassadorImage3} />
                                                    <p className="name">Jhamaica Publico</p>
                                                    <p className="position">Isabela State University</p>
                                                    <p className="description">EVP Participant from</p>
                                                    <p className="description-blue">Kurt Weiss</p>
                                                    <p className="description">at Center Moriches, NY</p>
                                                    {/* <p className="caption">Congratulations! We are proud of you. Inviting all Graduates of Agriculture Courses who want to experience Agricultural Training in the United States.</p> */}
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <div>
                                    <div className="carousel-ambassador">
                                        <Row gutter={12}>
                                            <Col span={8}>
                                                <div className="card-container">
                                                    <Avatar size={110} src={aboutAmbassadorImage4} />
                                                    <p className="name">Michael Simbajon</p>
                                                    <p className="position">Central Mindanao University</p>
                                                    <p className="description">EVP Participant from</p>
                                                    <p className="description-blue">Loomis Pork LLC</p>
                                                    <p className="description">at Loomis, NE</p>
                                                    {/* <p className="caption">Congratulations! We are proud of you. Inviting all Graduates of Agriculture Courses who want to experience Agricultural Training in the United States.</p> */}
                                                </div>
                                            </Col>
                                            <Col span={8}>
                                                <div className="card-container blue-card">
                                                    <Avatar size={110} src={aboutAmbassadorImage5} />
                                                    <p className="name">John Hurry P. Valdez</p>
                                                    <p className="position">Bulacan Agricultural State College</p>
                                                    <p className="description">EVP Participant from</p>
                                                    <p className="description-white">Nelson’s Poultry Farm</p>
                                                    <p className="description">at Manhattan, KS</p>
                                                    {/* <p className="caption">Congratulations! We are proud of you. Inviting all Graduates of Agriculture Courses who want to experience Agricultural Training in the United States.</p> */}
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Carousel>
                        </Col>
                        <Button onClick={handleNextClick} icon={<RightOutlined />} shape="circle" tpype="primary"></Button>
                    </Row>
                </div>
                <div className="career-container">
                    <div className="career-header">
                        <p className="blue-header text-align-center">Graduates</p>
                        <Row align="middle">
                            <div className="zigzag"/>
                            <h1>KICK START YOUR CAREER</h1>
                            <img className="zigzag" src={zigzag}/>
                        </Row>
                    </div>
                    <div className="card-background">
                        <div className="card-container">
                            <Row>
                                <Col span={8}>
                                    <div className="service-content">
                                        <img src={aboutCareerImage1}/>
                                        <h2>Requirements</h2>
                                        <p>
                                            Attend FAREAST Orientation to know and understand the details of Exchange Visitor Program and your training program. FAREAST will help you to complete all required documents. Email scanned copy of the following:
                                        </p>
                                        <Link to={"/seemore/requirements"}>See more <RightCircleFilled /></Link>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <div className="service-content divider">
                                        <img src={aboutCareerImage2}/>
                                        <h2>Online Application, Training, And Interview</h2>
                                        <p>
                                            You will be invited to create your own account to complete your online application with the US Sponsor. FAREAST will assist you to complete your online application. A part of your application, you are required to attend a one-day English Training Refresher Course Training to enhance your communication and certify that you can speak in English. To explain more details about your training, you are required to attend a Skype Interview to meet representative of your US Sponsor.
                                        </p>
                                        <Link to={"/seemore/online"}>See more <RightCircleFilled /></Link>
                                    </div>
                                </Col>

                                <Col span={8}>
                                    <div className="service-content divider">
                                        <img src={aboutCareerImage3}/>
                                        <h2>Signing Of Documents / J1 Visa Interview Schedule</h2>
                                        <p>
                                            FAREAST will help you to understand all the details of your program training in the United States. You will be assisted to accept, and sign required documents. We will assist you in your J1 visa interview schedule and attend series of embassy coaching. You will be assisted with all the required documents to attend the pre-departure orientation and secure CFO sticker.
                                        </p>
                                        <Link to={"/seemore/signing"}>See more <RightCircleFilled /></Link>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className="benefits">
                        <p className="secondary-title">Benefits</p>
                        <Row gutter={29}>
                            <Col span={8}>
                                <p className="description">
                                    ● Learn about American Culture and promote our own country and culture. <br/>
                                    ● Add an international experience in your CV/Resume. <br/>
                                    ● Develop your Knowledge and Skills. <br/>
                                    ● Improve your communication and interpersonal skills.<br/>
                                </p>
                            </Col>
                            <Col span={8}>
                                <p className="description">
                                    ● You'll have a chance to meet new friends from other countries. <br/>
                                    ● You will be provided Medical Insurance during your training. <br/>
                                    ● Received a Certification of Completion after your training. <br/>
                                    ● Received a stipend allowance during your training.<br/>
                                </p>
                            </Col>
                            <Col span={8}>
                                <p className="description">
                                    ● See and experience being in popular tourist destinations, restaurants, or amusement parks across the U.S. <br/>
                                    ● Participate in a structured and guided work-based training program based on your academic field. <br/>
                                    ● Experience, exposure to American techniques, methodologies, and expertise. <br/>
                                    ● Experience participating in different American Holidays, Sports, and other Activities.<br/>
                                </p>
                            </Col>
                        </Row>
                        <button className="join-now-btn" onClick={() => handleScrollTo("contact-container")}>Join Now <RightCircleFilled /></button>

                    </div>
                    
                </div>
                <div className="faq-container">
                    <p className="blue-header text-align-center">Exchange Visitor Program</p>
                    <Row align="middle" className="header">
                        <img className="zigzag" src={zigzag}/>
                        <h1>FREQUENTLY ASKED QUESTIONS</h1>
                        <div className="zigzag"/>
                    </Row>
                    <Collapse
                        defaultActiveKey={0}
                        bordered={false}
                        expandIconPosition="end"
                        expandIcon={({ isActive }) => isActive ? <MinusOutlined className="expand-icon" /> : <PlusOutlined className="expand-icon" />}
                        items={getItems()}
                        ghost
                    />
                </div>
                <div className="testimonial-container">
                    <div className="upper-container">
                        <Row align="middle">
                            <img className="zigzag" src={zigzag}/>
                            <h1>TESTIMONIALS</h1>
                            <div className="zigzag"/>
                        </Row>
                    </div>
                    <Row align={"middle"} justify={"center"} gutter={100}>
                        <Button onClick={handlePrevClickTesti} icon={<LeftOutlined />} shape="circle" tpype="primary"></Button>
                        <Col span={20}>
                            <Carousel ref={carouselRefTesti} dots={false}>
                                <div>
                                    <div className="cards">
                                        <Row gutter={12}>
                                            <Col span={8}>
                                                <div className="card-container">
                                                    {/* <ReactPlayer 
                                                        url={video1} 
                                                        // controls 
                                                        style={{
                                                            borderRadius: "8px", 
                                                            backgroundColor: "black"
                                                        }} 
                                                        width={"100%"}
                                                        playing
                                                        playIcon={<button>Play</button>}
                                                    /> */}
                                                    <video src={millan} controls/>
                                                    <p className="name">Millan, Christian</p>
                                                    <p className="position">Tarlac Agricultural University</p>
                                                    {/* <p className="caption">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et</p> */}
                                                </div>
                                            </Col>
                                            <Col span={8}>
                                                <div className="card-container blue-card">
                                                    <video src={publacion} controls/>
                                                    <p className="name">Publico, Jhamaica</p>
                                                    <p className="position">Isabela State University</p>
                                                    {/* <p className="caption">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et</p> */}
                                                </div>
                                            </Col>
                                            <Col span={8}>
                                                <div className="card-container">
                                                    <video src={simbajon} controls/>
                                                    <p className="name">Simbajon, Michael</p>
                                                    <p className="position">Central Mindanao State University</p>
                                                    {/* <p className="caption">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et</p> */}
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <div>
                                    <div className="cards">
                                        <Row gutter={12}>
                                            <Col span={8}>
                                                <div className="card-container">
                                                    <video src={nodetails} controls/>
                                                    <p className="name">Cano, Ethelbert</p>
                                                    <p className="position">Batanes State College</p>
                                                    {/* <p className="caption">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et</p> */}
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Carousel>
                        </Col>
                        <Button onClick={handleNextClickTesti} icon={<RightOutlined />} shape="circle" tpype="primary"></Button>
                    </Row>
                    
                </div>
            </div>
            <div id="contact-container">
                <div className="card-container">
                    <Row>
                        <Col span={12} className="left-container">
                            <p className="blue-header">Contact us</p>
                            <h1>IF YOU HAVE ANY QUERY,<br/>PLEASE CONTACT US</h1>
                            <img className="map" src={map}/>
                            <p className="title">Contact details</p>
                            <Row align={"middle"} className="details">
                                <img className="icon" src={location}/>
                                <p>912 Arnaiz Avenue, Makati City</p>
                            </Row>
                            <Row align={"middle"} className="details">
                                <img className="icon" src={phone}/>
                                <p>09668021992</p>
                            </Row>
                            <Row align={"middle"} className="details">
                                <img className="icon" src={mail}/>
                                <p>usdocs@fareastj1.org</p>
                            </Row>
                            <Row justify={"space-between"}>
                                <div>
                                    <p className="title">Media Links</p>
                                    <Row align={"middle"} className="details">
                                        <img className="icon" src={facebook}/>
                                        <a href="https://www.facebook.com/fareastj1visa?mibextid=2JQ9oc" target="_blank"><p>Fareast Usatraining</p></a>
                                    </Row>
                                </div>
                                <div>
                                    <p className="title">QR Code</p>
                                    <QRCode 
                                        value={"https://www.facebook.com/fareastj1visa?mibextid=2JQ9oc"} 
                                        color="white"
                                        bgColor="#1164A8"
                                        size={102}
                                    />
                                </div>
                            </Row>
                        </Col>
                        <Col span={12} className="right-container">
                            <p className="black-header">Send a message</p>
                            <br/>
                            {/* <p className="description">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam</p> */}
                            <div class="form">
                            <form
                                id="contact-form"
                                class="form ant-form ant-form-vertical css-dev-only-do-not-override-txh9fw"
                                ref={ref}
                                onSubmit={onFinish}
                            >
                                <div className="form-item">
                                    <label>Name</label>
                                    <Input placeholder="Enter your full name here" name="fullName" required/>
                                </div>
                                <div className="form-item">
                                    <label>Email</label>
                                    <Input placeholder="Enter your email here" type="email" name="email" required/>
                                </div>
                                
                                <div className="form-item">
                                    <label>Message</label>
                                    <Input placeholder="Enter your subject here" name="subject" required/>
                                </div>
                                <div className="form-item">
                                    <label>Message</label>
                                    <TextArea placeholder="Aa" allowClear autoSize={{minRows: 3, maxRows: 3}}  name="message" required/>
                                </div>
                                <br/>
                                <br/>

                                <Row justify={"end"} align={"bottom"}>
                                    <button className="submit-btn" type="submit">Send</button>
                                </Row>
                            </form>

                                {/* <form id="contact-form" class="ant-form ant-form-vertical css-dev-only-do-not-override-txh9fw" ref={ref} onSubmit={onFinish}>
                                    <div class="ant-form-item css-dev-only-do-not-override-txh9fw ant-form-item-has-success">
                                        <div class="ant-row ant-form-item-row css-dev-only-do-not-override-txh9fw">
                                            <div class="ant-col ant-form-item-label css-dev-only-do-not-override-txh9fw">
                                                <label for="fullName" class="ant-form-item-required" title="Full Name">Full Name</label>
                                            </div>
                                            <div class="ant-col ant-form-item-control css-dev-only-do-not-override-txh9fw">
                                                <div class="ant-form-item-control-input">
                                                    <div class="ant-form-item-control-input-content">
                                                        <input placeholder="Enter your full name here" id="fullName" aria-required="true" class="ant-input ant-input-status-success css-dev-only-do-not-override-txh9fw" type="text" value="Test"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form> */}
                            </div>
                            {/* <div className="form">
                                <Form  
                                    id="contact-form"
                                    form={form}
                                    // ref={ref}
                                    layout="vertical"
                                    onFinish={onFinish}
                                >
                                    <Form.Item
                                        label="Full Name"
                                        name="fullName"
                                        rules={[{ required: true, message: 'This field is required!' }]}
                                    >
                                        <Input placeholder="Enter your full name here"/>
                                    </Form.Item>
                                    <Form.Item
                                        label="Email"
                                        name="email"
                                        rules={[{ required: true, message: 'This field is required!' }]}
                                    >
                                        <Input placeholder="Enter your email here" type="email"/>
                                    </Form.Item>
                                    <Form.Item
                                        label="Subject"
                                        name="subject"
                                        rules={[{ required: true, message: 'This field is required!' }]}
                                    >
                                        <Input placeholder="Enter your subject here"/>
                                    </Form.Item>
                                    <Form.Item
                                        label="Message"
                                        name="message"
                                        rules={[{ required: true, message: 'This field is required!' }]}
                                    >
                                        <TextArea placeholder="Aa" allowClear autoSize={{minRows: 3, maxRows: 3}} />
                                    </Form.Item>
                                    <Row justify={"end"} align={"bottom"}>
                                        <button className="submit-btn" type="submit">Send</button>
                                    </Row>
                                </Form>
                            </div> */}
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="blue-divider"/>
            <footer>
                <Row gutter={59} className="upper-container">
                    <Col span={6}>
                        <img className="logo" src={logo}/>
                    </Col>
                    <Col span={6}>
                        <p className="blue-header">Our Office</p>
                        <Row align={"middle"} className="details">
                            <img className="icon" src={locationBlack}/>
                            <p>912 Arnaiz Avenue, Makati City</p>
                        </Row>
                        <Row align={"middle"} className="details">
                            <img className="icon" src={phoneBlack}/>
                            <p>09668021992</p>
                        </Row>
                        <Row align={"middle"} className="details">
                            <img className="icon" src={mailBlack}/>
                            <p>usdocs@fareastj1.org</p>
                        </Row>
                    </Col>
                    <Col span={6}>
                        <p className="blue-header">Business Hours</p>
                        <Row align={"top"} className="details">
                            <img className="icon" src={carretBlack}/>
                            <Col>
                                <p>Monday - Friday</p>
                                <p className="time-text">09:00 am - 06:00 pm</p>
                            </Col>
                        </Row>
                        <Row align={"top"} className="details">
                            <img className="icon" src={carretBlack}/>
                            <Col>
                                <p>Saturday - Sunday</p>
                                <p className="time-text">Closed</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={6}>
                        <p className="blue-header">Quick Links</p>
                        <ul>
                            <li>
                                <Row align={"middle"} className="details">
                                    <img className="icon" src={carretBlack}/>
                                    <a onClick={() => handleScrollTo("home-container")} >Home</a>
                                </Row>
                            </li>
                            <li>
                                <Row align={"middle"} className="details">
                                    <img className="icon" src={carretBlack}/>
                                    <a onClick={() => handleScrollTo("services-container")}>Services</a>
                                </Row>
                            </li>
                            <li>
                                <Row align={"middle"} className="details">
                                    <img className="icon" src={carretBlack}/>
                                    <a onClick={() => handleScrollTo("about-container")}>About</a>
                                </Row>
                            </li>
                            <li>
                                <Row align={"middle"} className="details">
                                    <img className="icon" src={carretBlack}/>
                                    <a onClick={() => handleScrollTo("contact-container")}>Contact</a>
                                </Row>
                            </li>
                        </ul>
                    </Col>
                </Row>
                <div className="copy-right">
                    <p>© FAREAST, All Right Reserved.</p>
                </div>
            </footer>
        </div>
    )
}

export default Home