import { Avatar, Carousel, Col, Collapse, Divider, Form, Image, Input, Layout, Progress, QRCode, Row, Space, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { RightCircleFilled, PlusOutlined, MinusOutlined } from '@ant-design/icons';

import logo from "../../asset/logo.png"
import zigzag from "../../asset/zigzag.png"

import servicesImage1 from "../../asset/services/image-1.png"
import servicesImage2 from "../../asset/services/image-2.png"
import servicesImage3 from "../../asset/services/image-3.png"

import aboutCareerImage1 from "../../asset/about/career/image-1.png"
import aboutCareerImage2 from "../../asset/about/career/image-2.png"
import aboutCareerImage3 from "../../asset/about/career/image-3.png"

import aboutProgramImage1 from "../../asset/about/program/image-1.png"
import aboutProgramImage2 from "../../asset/about/program/image-2.png"
import aboutProgramImage3 from "../../asset/about/program/image-3.png"
import aboutProgramImage4 from "../../asset/about/program/image-4.png"
import aboutProgramImage7 from "../../asset/about/program/image-7.png"
import aboutProgramImage8 from "../../asset/about/program/image-8.png"

import facebook from "../../asset/icons/facebook.svg"
import locationBlack from "../../asset/icons/location-black.svg"
import mailBlack from "../../asset/icons/mail-black.svg"
import phoneBlack from "../../asset/icons/phone-black.svg"
import carretBlack from "../../asset/icons/caret-black.svg"

import "./style.scss"
import { Link, useParams } from "react-router-dom";

const SeemoreEVP = () => {
    const {type} = useParams()

    const [currentData, setcurrentData] = useState();

    useEffect(() => {
        if(type == "poultry") {
            setcurrentData(data[0])
        } else if(type == "dairy") {
            setcurrentData(data[1])
        } else if(type == "swine") {
            setcurrentData(data[2])
        } else if(type == "crops") {
            setcurrentData(data[3])
        } else if(type == "evp") {
            setcurrentData(data[4])
        } else if(type == "j1-visa") {
            setcurrentData(data[5])
        }
    },[type])

    const data = [
        {
            image: aboutProgramImage1,
            title: 'Poultry Production',
        },
        {
            image: aboutProgramImage2,
            title: 'Dairy Production',
        },
        {
            image: aboutProgramImage3,
            title: 'Swine Production',
        },
        {
            image: aboutProgramImage4,
            title: 'Crops Production',
        },
        {
            image: aboutProgramImage7,
            title: 'WHAT IS A J1 VISA?',
        },
        {
            image: aboutProgramImage8,
            title: 'WHAT IS EXCHANGE VISITOR PROGRAM?',
        },
    ]


    return (
        <div className="seemore-evp">
            <nav>
                <img className="logo" src={logo}/>
                <div className="nav-links-container">
                    <ul>
                        <li>
                            <Link to={"/"}>Home</Link>
                        </li>
                    </ul>
                    <div className="divider"/>
                    <a className="facebook" target="_blank" href="https://www.facebook.com/fareastj1visa?mibextid=2JQ9oc"><img className="icon" src={facebook}/></a>
                </div>
            </nav>
            <div className="green-divider"/>
            <div id="about-container">
                <div className="program-container">
                    <div className="card-container">
                        {/* <p className="blue-header text-align-center">Exchange Visitor Program</p> */}
                        <Row align="middle">
                            <img className="zigzag" src={zigzag}/>
                            <h1>USA AGRICULTURAL TRAINING PROGRAMS</h1>
                            <div className="zigzag"/>
                        </Row>
                        <div className="card-content">
                            <Row gutter={50}>
                                <Col span={10}>
                                    <Image src={currentData?.image}/>
                                </Col>
                                <Col span={14}>
                                    <div className="left-container">
                                        <p className="blue-header">{currentData?.title}</p>
                                        {type && type == "poultry" ? (
                                            <ul>
                                                <li>Safety and Operations Training</li>
                                                <li>Flock Management – Avian Husbandry</li>
                                                <li>Poultry Feed and Nutrition</li>
                                                <li>Poultry Flock Management – Data and Documentation</li>
                                                <li>Flock Management – Technology and Automation</li>
                                                <li>Supervision and Poultry Operations Management</li>
                                            </ul>
                                        ):(
                                            type && type == "dairy" ? (
                                                <ul>
                                                    <li>Introduction and Orientation of Farm Operations </li>
                                                    <li>Basic Dairy Herd Care</li>
                                                    <li>Advanced Dairy Herd Management</li>
                                                    <li>Advanced Dairy Herd Management II</li>
                                                    <li>Management and Oversight</li>
                                                </ul>
                                            ):(
                                                type && type == "swine" ? (
                                                    <ul>
                                                        <li>Introduction to Farrowing</li>
                                                        <li>Swine Lactation and Recovery</li>
                                                        <li>Intermediate Farrowing/ Barn Management</li>
                                                        <li>Advanced Swine Production – Breeding</li>
                                                        <li>Management and Oversight</li>
                                                    </ul>
                                                ): (
                                                    type && type == "crops" ? (
                                                        <ul>
                                                            <li>Crop Management and Grower Operations</li>
                                                            <li>Safety and Operations Training</li>
                                                            <li>Managing Farm Stands and Value-Added Products</li>
                                                            <li>Irrigation and Plant Hydration</li>
                                                            <li>Marketing of Produce</li>
                                                            <li>Advanced Crop Management</li>
                                                            <li>Management and Oversight</li>
                                                        </ul>
                                                    ): (
                                                        type && type == "evp" ? (
                                                            <p className="description">
                                                                A J-1 visa is a non-immigrant visa issued by the United States to research scholars, professors and exchange visitors participating in programs that promote cultural exchange, especially to obtain medical or business training within the U.S. All applicants must meet eligibility criteria, English language requirements, and be sponsored either by a university, private sector or government program. J-1 visa holders must return home for two years following visa expiration so they impart cultural knowledge learned in the United States.
                                                            </p>
                                                        ): (
                                                            type && type == "j1-visa" ? (
                                                                <>
                                                                    <br/>
                                                                    <p className="description">
                                                                        Exchange Visitor Program refers to the international exchange program administered by the United States to implement the Mutual Education and Cultural Exchange Act of 1961, as amended, Public Law 87-256, 22 USC 2451, et seq. (1988). The purpose of the Act is to increase mutual understanding between the people of the United States and the people of other countries by means of educational and cultural exchanges. Educational and cultural exchanges assist in furthering the United States' foreign policy objectives.
                                                                    </p>
                                                                </>
                                                            ):<></>
                                                        )
                                                    )
                                                )
                                            )
                                        )}
                                    </div>
                                </Col>
                                <div className="bottom-content">
                                    {type && type == "j1-visa" ? (
                                        <>
                                            <br/>
                                            <p className="description">
                                                The establishment and development of the Exchange Visitor Program (EVP) may extend as far back as January 1948, when the United States Congress passed the Information and Education Exchange Act, also known as the Smith-Mundt Act. The law was intended "to increase mutual understanding between the people of the United States and the peoples of other countries" through:
                                            </p>
                                            <ul>
                                                <li>An information service to disseminate abroad information about the United States, its people, and its policies;</li>
                                                <li>An educational exchange service to cooperate with other nations in the interchange of persons, knowledge and skills; the rendering of technical and other services; and the interchange of developments in the field of education, arts, and sciences.</li>
                                            </ul>
                                        </>
                                    ):<></>}
                                </div>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
            <footer>
                <Row gutter={59} className="upper-container">
                    <Col span={6}>
                        <img className="logo" src={logo}/>
                    </Col>
                    <Col span={9}>
                        <p className="blue-header">Our Office</p>
                        <Row align={"middle"} className="details">
                            <img className="icon" src={locationBlack}/>
                            <p>912 Arnaiz Avenue, Makati City</p>
                        </Row>
                        <Row align={"middle"} className="details">
                            <img className="icon" src={phoneBlack}/>
                            <p>09668021992</p>
                        </Row>
                        <Row align={"middle"} className="details">
                            <img className="icon" src={mailBlack}/>
                            <p>usdocs@fareastj1.org</p>
                        </Row>
                    </Col>
                    <Col span={9}>
                        <p className="blue-header">Business Hours</p>
                        <Row align={"top"} className="details">
                            <img className="icon" src={carretBlack}/>
                            <Col>
                                <p>Monday - Friday</p>
                                <p className="time-text">09:00 am - 06:00 pm</p>
                            </Col>
                        </Row>
                        <Row align={"top"} className="details">
                            <img className="icon" src={carretBlack}/>
                            <Col>
                                <p>Saturday - Sunday</p>
                                <p className="time-text">Closed</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <div className="copy-right">
                    <p>© FAREAST, All Right Reserved.</p>
                </div>
            </footer>
        </div>
    )
}

export default SeemoreEVP